const ROUTES = {
  HOME: "/",
  REGISTER: "/register",
  LOGIN: "/login",
  MENU: "/menu",
  MENULOGUT: "/generalmenu",
  CREATE:"/creat",
  CRM:"/crm",
  ABOUT:"/about",
  LOGOUT: "/logout",
  PAYMENT:"/payment",
  MYORDER:"/my-order",
  BOOKTABLE:"/book-Table",
  CONTACT:"/contact",
  PROFAIL:"/profail"
};

export default ROUTES;
